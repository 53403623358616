import { Component, Vue } from "vue-property-decorator";
import { ValidateIf, IsDefined, IsNotEmpty, MaxLength, validate } from "class-validator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { HomeworkModel, QuizModel } from "@/models";
import { Common } from "../Common";

export class QuestionForm {
    @IsDefined() @IsNotEmpty({ message: "必填" }) grade = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) level = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) unit = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) difficulty = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) tid = "";
    @IsDefined() @IsNotEmpty({ message: "必填" }) type = '1';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(1000, { message: "最多輸入1000字" }) question = '';
    pic = ''; //圖片需有初始值
    @ValidateIf(o => o.type === '1' || o.type === '2') @IsNotEmpty({ message: "必填" }) option1 = '';
    @ValidateIf(o => o.type === '1' || o.type === '2') @IsNotEmpty({ message: "必填" }) option2 = '';
    option3 = '';
    option4 = '';
    @IsDefined() @MaxLength(500, { message: "最多輸入500字" }) suggestAns = '';
    @ValidateIf(o => o.type === '1' || o.type === '2') @IsNotEmpty({ message: "必填" }) answer = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) active = "1";
}

type response = { [key: string]: unknown };

@Component<AdminHomeworkQuestionAddController>({
    components: {
        SideNav, Lightbox
    }
})
export default class AdminHomeworkQuestionAddController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private questionForm = new QuestionForm();
    private teacherList: object = {};
    private gradeList: object = {};
    private levelList: object = {};
    private unitList: response = {};
    private token = window.localStorage.getItem('adminToken') as string;

    private errorMsg = "";
    private openLB = false;
    private errNo = 0;

    private errorMsgMap: { [key: string]: string } = {
        grade: '',
        unit: '',
        level: '',
        difficulty: '',
        tid: '',
        type: '',
        question: '',
        option1: '',
        option2: '',
        suggestAns: '',
        answer: '',
        active: '',
    };

    public async created() {
        this.getTeacher();
        this.gradeList = await Common.getGrade();
    }

    private async getLevel() {
        this.questionForm.level = '';
        this.questionForm.unit = '';
        const grade = this.questionForm.grade;
        this.levelList = await Common.getLevel(grade);
        this.unitList = {};
    }

    private async getUnit() {
        this.questionForm.unit = '';
        this.unitList = await Common.getUnit();
    }

    // 依照登入者身分列出可選老師
    private async getTeacher() {
        const identity = window.localStorage.getItem('admin_identity') as string;
        const sid = window.localStorage.getItem('admin_sid') as string;

        const item: { type: number, identity: string, sid: string, token: string } = {
            type: 1, //取active = 1
            identity: identity,
            sid: sid,
            token: this.token,
        }
        const data = await QuizModel.getTeacher(item) as response;
        Common.checkToken(data.ERR_CODE as number);
        this.teacherList = data.list as object;
    }

    private previewPic(event: any) {
        const file = event.target.files[0];

        if (file) { //若有上傳圖片
            /* 限制檔案上傳型別 */
            const suffixName = file.name.substring(file.name.lastIndexOf('.') + 1);   // 得到檔案字尾名
            if (suffixName !== 'jpg' && suffixName !== 'png' && suffixName !== 'gif' && suffixName !== 'jpeg') {
                this.errorMsg = '上傳檔案只能是 jpg、png、gif、jpeg 格式！';
                this.openLB = true;
                return;
            }
            /* 限制檔案上傳大小 */
            const isLimitSize = file.size / 1024 / 1024 < 3;
            if (!isLimitSize) {
                this.errorMsg = '上傳檔案不能超過 3MB！';
                this.openLB = true;
                return;
            }
            const reader = new FileReader();

            reader.onload = (e: any) => {
                this.questionForm.pic = e.target.result;
            };
            reader.readAsDataURL(file);
        }
    }

    private delPic() {
        this.questionForm.pic = '';
    }

    private validateAndSubmit() {
        validate(this.questionForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        grade: '',
                        unit: '',
                        level: '',
                        difficulty: '',
                        tid: '',
                        question: '',
                        type: '',
                        option1: '',
                        option2: '',
                        suggestAns: '',
                        answer: '',
                        active: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const formobj: HTMLFormElement = document.getElementById('questionAdd') as HTMLFormElement;
                    const data = new FormData(formobj); //創建空的Formdata object

                    data.append('token', this.token);

                    this.errNo = await HomeworkModel.addQuestion(data);
                    Common.checkToken(this.errNo);
                    this.openLB = true;
                    this.doubleClick = false;
                    this.errorMsg = ErrorMessage[this.errNo];
                }
            }
        )
    }

    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.push("/admin/homework/question");
        }
    }
}
